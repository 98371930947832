import { getClubsWithStats } from './api'
import { showDataAlerts, showWelcomeModal } from './utils';

(async function() {

  function displayClubStats(data) {
    let clubsDiv = document.getElementById("clubs")
    clubsDiv.innerHTML = ""

    function getOfflineStats(leftOfflineBias, rightOfflineBias, straightOnlineBias, rightOfflineAverage, leftOfflineAverage) {
      if(rightOfflineBias > straightOnlineBias && rightOfflineBias > leftOfflineBias){
        return "Right "+rightOfflineAverage.toFixed(2)
      } else if(leftOfflineBias > straightOnlineBias && leftOfflineBias > rightOfflineBias){
        return "Left "+(-leftOfflineAverage.toFixed(2))
      } else if(straightOnlineBias > leftOfflineBias && straightOnlineBias > rightOfflineBias) {
        return "Straight"
      } else if(-leftOfflineAverage > rightOfflineAverage){
        return "Left "+(-leftOfflineAverage.toFixed(2))
      } else if (rightOfflineAverage > -leftOfflineAverage){
        return "Right "+rightOfflineAverage.toFixed(2)
      } else {
        return "Straight"
      }
    }

    function getClubName(club){
      return club.nickname === "" ? club.displayName : club.nickname
    }

    function getLastHit(stats) {
      return stats.lastHit ? stats.lastHit.split("T")[0] : "Never hit"
    }

    let units = data.units === "METERS" ? "m" : " yds"

    data.clubsWithStats.forEach((clubWithStats) => {
      let stats = clubWithStats.stats
      clubsDiv.innerHTML = clubsDiv.innerHTML 
       +`<div class='panel panel-success item club' ontouchstart=$(this).addClass('hover') ontouchend=$(this).addRemove('hover'); onclick=\"window.location='/dashboard.html?club=${clubWithStats.club.guid}&clubName=${getClubName(clubWithStats.club)}';\">`
       +`<div class='panel-heading'><strong>${getClubName(clubWithStats.club)}</strong><span class='click-info-label label label-primary club-tooltip'>Click to view performance charts for ${getClubName(clubWithStats.club)}</span></div><div class='panel-body'>`
        +`<p class='longestDistanceStats'>Longest Distance: ${stats.longestDistance.toFixed(1)}${units}</p>`
        +`<p class='goodDistanceStats'>Average Good Distance: ${stats.averageGoodDistance.toFixed(1)}${units}</p>`
        +`<p class='averageDistanceStats'>Average Distance: ${stats.averageDistance.toFixed(1)}${units}</p>`
        +`<p class='goodCarryStats'>Average Good Carry: ${stats.averageGoodCarry.toFixed(1)}${units}</p>`
        +`<p class='averageCarryStats'>Average Carry: ${stats.averageCarry.toFixed(1)}${units}</p>`
        +`<p class='averageOfflineStats'>Average Offline: ${getOfflineStats(stats.leftOfflineBias, stats.rightOfflineBias, stats.straightOnlineBias, stats.rightOfflineAverage, stats.leftOfflineAverage)}${units}</p>`
        +`<p class='averageHeightStats'>Average Height: ${stats.averageHeight.toFixed(1)}${units}</p>`
        +`<div class='successRateStats'>Success Rate (${(stats.successRate*100).toFixed(1)}%):</p>`
        +"<div class='progress' style='max-width:30em'>"
          +`<div class='progress-bar success-bar' role='progressbar' style='width: ${stats.successRate*100}%' aria-valuenow='${stats.successRate*100}' aria-valuemin='0' aria-valuemax='100'></div>`
          +`<div class='progress-bar failed-bar' role='progressbar' style='width: ${100-stats.successRate*100}%' aria-valuenow='${100-stats.successRate*100}' aria-valuemin='0' aria-valuemax='100'></div>`
        +"</div></div>"
        +`<p class='lastHitStats'>Last Hit: ${getLastHit(stats)}</p>`
        +`<p class='totalShotsStats'>Total Shots: ${stats.totalShots}</p>`
        +"</div></div>"
    })

    $('.clubOrdering a').on("click", function(e){
      $('.clubOrdering li').removeClass("active");
      $(this).parent().addClass("active");
      let orderingNo = $(this).attr("data-value") ? $(this).attr("data-value") : 1
      getClubsWithStats(orderingNo, displayClubStats);
    });

    $("#clubFiltering .dropdown-menu").on('click', function (e) {
      e.stopPropagation();
    });

    $('#clubFiltering').on('hide.bs.dropdown', function() {
      function hideOrShowStats(isEnabled, stat) {
        if(isEnabled) {
          stat.fadeIn()
        } else {
          stat.fadeOut()
        }
      }

      let longestDistanceEnabled = document.getElementById("longestDistanceEnabled") ? document.getElementById("longestDistanceEnabled").checked : true
      let goodDistanceEnabled = document.getElementById("goodDistanceEnabled") ? document.getElementById("goodDistanceEnabled").checked : true
      let averageDistanceEnabled = document.getElementById("averageDistanceEnabled") ? document.getElementById("averageDistanceEnabled").checked : true
      let goodCarryEnabled = document.getElementById("goodCarryEnabled") ? document.getElementById("goodCarryEnabled").checked : true
      let averageCarryEnabled = document.getElementById("averageCarryEnabled") ? document.getElementById("averageCarryEnabled").checked : true
      let averageOfflineEnabled = document.getElementById("averageOfflineEnabled") ? document.getElementById("averageOfflineEnabled").checked : true
      let averageHeightEnabled = document.getElementById("averageHeightEnabled") ? document.getElementById("averageHeightEnabled").checked : true
      let successRateEnabled = document.getElementById("successRateEnabled") ? document.getElementById("successRateEnabled").checked : true
      let lastHitEnabled = document.getElementById("lastHitEnabled") ? document.getElementById("lastHitEnabled").checked : true
      let totalShotsEnabled = document.getElementById("totalShotsEnabled") ? document.getElementById("totalShotsEnabled").checked : true


      hideOrShowStats(longestDistanceEnabled, $(".longestDistanceStats"));
      hideOrShowStats(goodDistanceEnabled, $(".goodDistanceStats"));
      hideOrShowStats(averageDistanceEnabled, $(".averageDistanceStats"));
      hideOrShowStats(goodCarryEnabled, $(".goodCarryStats"));
      hideOrShowStats(averageCarryEnabled, $(".averageCarryStats"));
      hideOrShowStats(averageOfflineEnabled, $(".averageOfflineStats"));
      hideOrShowStats(averageHeightEnabled, $(".averageHeightStats"));
      hideOrShowStats(successRateEnabled, $(".successRateStats"));
      hideOrShowStats(lastHitEnabled, $(".lastHitStats"));
      hideOrShowStats(totalShotsEnabled, $(".totalShotsStats"));
    })
  }

  getClubsWithStats(1, displayClubStats);

  showDataAlerts();
  showWelcomeModal();
})();
